import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/general/Layout";
import HorizontalScrollTemplate from "../components/templates/HorizontalScrollTemplate";

const ProjectPage = ({ data }) => {
  const projectPage = data.prismicProject;
  return (
    <Layout>
      <HorizontalScrollTemplate projectPage={projectPage} />
    </Layout>
  );
};

export const query = graphql`
  query projectPageQuery($id: String, $lang: String) {
    prismicProject(id: { eq: $id }, lang: { eq: $lang }) {
      data {
        color
        cover {
          gatsbyImageData
          alt
        }
        keywordone
        largeimage {
          gatsbyImageData
          alt
        }
        smallimage {
          alt
          gatsbyImageData
        }
        storie
        storietitle
        textcolor
        title {
          text
        }
      }
      alternate_languages {
        uid
        type
        lang
      }
    }
  }
`;

export default ProjectPage;
