import React, { useRef, useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

const StyledHorizontalContainer = styled.div`
  width: 400%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  background: ${(props) => props.theme.colors.white};

  .panel {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    .panel-intro-inner {
      position: relative;
      z-index: 10;
      height: 100%;
      width: 35vw;
      min-width: 400px;
      overflow: hidden;
      height: 100%;
      padding: 8vh 2vw;

      .SVG {
        height: 75%;
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        .slashSVG {
          fill: ${(props) => props.theme.colors.black};
        }
      }

      h1 {
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%) rotate(-90deg);
        font-family: ${(props) => props.theme.fonts.grotta};
        color: ${(props) => props.theme.colors.black};
        font-size: 5.3vw;
        letter-spacing: 0.4rem;
        width: 100%;
        text-align: center;
        @media screen and (min-width: ${(props) =>
            props.theme.responsive.medium}) {
          font-size: 1.8vw;
        }
      }

      .scroll-container {
        position: absolute;
        z-index: 2;
        bottom: 12.3%;
        right: 14%;
        font-family: ${(props) => props.theme.fonts.grotta};
        font-size: 1rem;
        letter-spacing: 1px;
        display: inline-block;
        transform-origin: right;
        pointer-events: none;

        .scroll-container-arrow {
          @keyframes bounce {
            0%,
            20%,
            50%,
            80%,
            100% {
              transform: translateX(0);
            }
            40% {
              transform: translateX(-10px);
            }
            60% {
              transform: translateX(-5px);
            }
          }

          position: relative;
          width: 36px;
          height: 1px;
          background-color: ${(props) => props.theme.colors.black};
          display: inline-block;
          vertical-align: middle;
          margin-left: 2em;
          -webkit-animation: bounce 2s infinite;
          animation: bounce 2s infinite;
          &:before {
            position: absolute;
            content: "";
            right: -2px;
            background-color: ${(props) => props.theme.colors.black};
            display: block;
            width: 1px;
            height: 12px;
            bottom: 0;
            transform-origin: bottom;
            transform: rotate(-45deg) translateY(-0.3px);
          }
          &:after {
            position: absolute;
            content: "";
            right: -2px;
            background-color: ${(props) => props.theme.colors.black};
            display: block;
            width: 1px;
            height: 12px;
            top: 0;
            transform-origin: top;
            transform: rotate(45deg) translateY(0.3px);
          }
        }
      }
    }
    .panel-image-inner {
      background: ${(props) => props.projectPage.data.color};
      position: relative;
      width: 65vw;
      height: 100%;

      .panel-large-image {
        position: absolute;
        right: 25%;
        top: 0;
        width: 387px;
        height: 76%;
        overflow: hidden;
        .gatsby-image-wrapper {
          height: 100%;
          transform: translateX(-20px);
        }
      }

      .panel-small-image {
        position: absolute;
        left: 25%;
        bottom: 0;
        width: 276px;
        height: 50%;
        overflow: hidden;
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
      .panel-category {
        position: absolute;
        right: 26.7%;
        top: 77%;
        h3 {
          color: ${(props) => props.projectPage.data.textcolor};
          font-family: ${(props) => props.theme.fonts.grotta};
          font-size: 5vh;
          letter-spacing: 8px;
        }
      }
    }
  }
  .panel-second-inner {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${(props) => props.projectPage.data.color};
    .panel-second-image {
      width: 50vw;
      height: 100%;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }

    .panel-second-content {
      position: absolute;
      bottom: 23.8%;
      left: -4%;
      font-family: ${(props) => props.theme.fonts.grotta};
      width: 15%;

      h2 {
        color: ${(props) => props.projectPage.data.textcolor};
        font-size: 2.2vh;
        letter-spacing: 2px;
        padding-bottom: 15px;
      }
      p {
        color: ${(props) => props.theme.colors.white};
        font-family: ${(props) => props.theme.fonts.body}, sans-serif;
        font-size: ${(props) => props.theme.fontSizes.bodyCopy};
        font-weight: ${(props) => props.theme.fontWeights.light};
        line-height: ${(props) => props.theme.lineHeights.bodyCopy};
        text-align: justify;
      }
    }
  }
`;

const HorizontalScrollTemplate = ({ projectPage }) => {
  const horizontalContainerRef = useRef();
  const parentRectRef = useRef();
  const tl = useRef();
  const SVGRef = useRef();

  const rect2Ref = useRef();
  const rect4Ref = useRef();
  const rect5Ref = useRef();
  const rect6Ref = useRef();
  const rect7Ref = useRef();
  const rect8Ref = useRef();
  const rect9Ref = useRef();
  const rect10Ref = useRef();
  const circle1Ref = useRef();
  const circle2Ref = useRef();

  const titleRef = useRef();
  const titleChars = useRef(null);

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }
    const sections = gsap.utils.toArray(".panel");
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none", // <-- IMPORTANT!
      scrollTrigger: {
        trigger: horizontalContainerRef.current,
        pin: true,
        scrub: 0.1,
        //snap: directionalSnap(1 / (sections.length - 1)),
        end: "+=3000",
        // markers: true,
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(DrawSVGPlugin, SplitText);
    }
    const mySplit = new SplitText(titleRef.current, {
      type: "words, chars",
    });
    titleChars.current = mySplit.chars;
    tl.current = gsap.timeline({
      defaults: {
        ease: "expo.inOut",
      },
    });
    tl.current
      .set([titleRef.current], {
        autoAlpha: 0,
      })
      .set([circle1Ref.current, circle2Ref.current], { drawSVG: "0%" })
      .from(parentRectRef.current.children, {
        y: 200,
        autoAlpha: 0,
        scaleY: 0,
        duration: 2,
        transformOrigin: "center",
        stagger: {
          each: 0.1,
          // from: 'random',
          from: "end",
        },
      })
      .to(rect2Ref.current, {
        skewY: -22.5,
        transformOrigin: "center",
        duration: 2,
      })
      .to(
        rect4Ref.current,
        {
          skewY: -13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        rect5Ref.current,
        {
          skewY: 13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        rect6Ref.current,
        {
          skewY: -13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        rect7Ref.current,
        {
          skewY: 13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        rect8Ref.current,
        {
          skewY: -13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        rect9Ref.current,
        {
          skewY: 13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        rect10Ref.current,
        {
          skewY: -13,
          transformOrigin: "center",
          duration: 2,
        },
        "-=2"
      )
      .to(
        circle1Ref.current,
        {
          drawSVG: "100%",
          duration: 2,
        },
        "-=2"
      )
      .to(
        circle2Ref.current,
        {
          drawSVG: "100%",
          duration: 2,
        },
        "-=2"
      )
      .set(titleRef.current, { autoAlpha: 1 }, "-=1.7")
      .from(
        titleChars.current,
        {
          duration: 1,
          opacity: 0,
          y: 30,
          stagger: 0.02,
        },
        "-=1.7"
      );
  }, []);

  return (
    <>
      <StyledHorizontalContainer
        ref={horizontalContainerRef}
        projectPage={projectPage}
      >
        <section className="panel panel-intro">
          <div className="panel-intro-inner">
            <svg
              ref={SVGRef}
              className="SVG"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 116 594"
            >
              <g className="slashSVG" ref={parentRectRef}>
                <rect
                  x="51.5"
                  y="531.4"
                  transform="matrix(6.123234e-17 -1 1 6.123234e-17 -525.838 641.838)"
                  width="13.1"
                  height="104.8"
                />
                <rect
                  ref={rect2Ref}
                  x="5.6"
                  y="542.7"
                  width="104.8"
                  height="13.2"
                />
                <rect
                  x="51.5"
                  y="462.5"
                  transform="matrix(6.123234e-17 -1 1 6.123234e-17 -456.8817 572.8817)"
                  width="13.1"
                  height="104.8"
                />
                <rect
                  ref={rect4Ref}
                  x="5.6"
                  y="349.9"
                  width="104.8"
                  height="13.1"
                />
                <rect
                  ref={rect5Ref}
                  x="5.6"
                  y="313"
                  width="104.8"
                  height="13.1"
                />
                <rect
                  ref={rect6Ref}
                  x="5.6"
                  y="276.2"
                  width="104.8"
                  height="13.1"
                />
                <rect
                  ref={rect7Ref}
                  x="5.6"
                  y="239.3"
                  width="104.8"
                  height="13.1"
                />
                <rect
                  ref={rect8Ref}
                  x="5.6"
                  y="202.4"
                  width="104.8"
                  height="13.1"
                />
                <rect
                  ref={rect9Ref}
                  x="5.6"
                  y="165.5"
                  width="104.8"
                  height="13.1"
                />
                <rect
                  ref={rect10Ref}
                  x="5.6"
                  y="128.6"
                  width="104.8"
                  height="13.1"
                />
              </g>
              <circle
                ref={circle1Ref}
                fill="none"
                stroke="#000"
                strokeWidth="12.2249"
                strokeMiterlimit="10"
                cx="58"
                cy="55.4"
                r="47.8"
              />
              <circle
                ref={circle2Ref}
                fill="none"
                stroke="#000"
                strokeWidth="12.2249"
                strokeMiterlimit="10"
                cx="58"
                cy="438.1"
                r="47.8"
              />
            </svg>
            <h1 ref={titleRef}>{projectPage.data.title.text}</h1>
            <span className="scroll-container">
              <span className="scroll-container-label">
                <span>scroll</span>
              </span>
              <span className="scroll-container-arrow"></span>
            </span>
          </div>
          <div className="panel-image-inner">
            <div className="panel-large-image">
              <GatsbyImage
                image={getImage(projectPage.data.largeimage.gatsbyImageData)}
                alt={projectPage.data.largeimage.alt}
              />
            </div>
            <div className="panel-small-image">
              <GatsbyImage
                image={getImage(projectPage.data.smallimage.gatsbyImageData)}
                alt={projectPage.data.smallimage.alt}
              />
            </div>
            <div className="panel-category">
              <h3>{projectPage.data.keywordone}</h3>
            </div>
          </div>
        </section>
        <section className="panel second-panel">
          <div className="panel-second-inner">
            <div className="panel-second-image">
              <GatsbyImage
                image={getImage(projectPage.data.cover.gatsbyImageData)}
                alt={projectPage.data.cover.alt}
              />
            </div>
            <div className="panel-second-content">
              <h2>{projectPage.data.storietitle}</h2>
              <p>{projectPage.data.storie}</p>
            </div>
          </div>
        </section>
        <section className="panel"></section>
        <section className="panel"></section>
      </StyledHorizontalContainer>
    </>
  );
};

export default HorizontalScrollTemplate;
